const prepareConfigTitle = (transaction_type: string, property_type: Array<string>, locale?: string) => {
	if (property_type !== undefined) {
		if (property_type.includes("Studio Apartment")) {
			/* Studio Apartment */

			return locale === "kn-IN" ? "ಕೊಠಡಿಗಳು" : locale === "ar-AE" ? "عدد الغرف" : "Bedrooms";
		} else if (property_type.includes("Co-living/PG")) {
			/* Co-living */

			if (transaction_type === "rent") {
				return locale === "kn-IN" ? "ಕೊಠಡಿಗಳು" : locale === "ar-AE" ? "عدد الغرف" : "Rooms";
			} else {
				return locale === "kn-IN" ? "ಕೊಠಡಿಗಳು" : locale === "ar-AE" ? "عدد الغرف" : "Rooms";
			}
		} else if (
			property_type.includes("Land (Residential)") ||
			property_type.includes("Land (Commercial)") ||
			property_type.includes("Land (Agricultural)") ||
			property_type.includes("Land (Industrial)") ||
			property_type.includes("Mining")
		) {
			/* Land types & Mining */

			return locale === "kn-IN" ? "ಭೂ ಪ್ರದೇಶ" : locale === "ar-AE" ? "مساحة الأرض" : "Land area";
		} else if (
			property_type.includes("Plot") ||
			property_type.includes("Farm Plot") ||
			property_type.includes("Plot (Commercial)") ||
			property_type.includes("Building") ||
			property_type.includes("Office Space") ||
			property_type.includes("Shop") ||
			property_type.includes("Factory") ||
			property_type.includes("Warehouse")
		) {
			/* Plots, Building, Shop, Office space, Factory, Mining, Warehouse */

			return locale === "kn-IN" ? "ಪ್ರದೇಶ" : locale === "ar-AE" ? "مساحة" : "Area";
		} else if (property_type.includes("Co-working")) {
			/* Co-working */

			if (transaction_type === "rent") {
				return locale === "kn-IN" ? "ಆಸನಗಳ ಸಂಖ್ಯೆ" : locale === "ar-AE" ? "عدد المقاعد" : "No of seats";
			} else {
				return locale === "kn-IN" ? "ಆಸನಗಳ ಸಂಖ್ಯೆ" : locale === "ar-AE" ? "عدد المقاعد" : "No of seats";
			}
		} else if (
			property_type.includes("Apartment") ||
			property_type.includes("Independent House") ||
			property_type.includes("Villa") ||
			property_type.includes("Row House") ||
			property_type.includes("Builder Floor Apartment") ||
			property_type.includes("Penthouse") ||
			property_type.includes("Farm House")
		) {
			/* Apartments, Independent House, Villa, Row House, Builder Floor Apartment, Penthouse, Farm House */

			return locale === "kn-IN" ? "ಕೊಠಡಿಗಳು" : locale === "ar-AE" ? "عدد الغرف" : "Bedrooms";
		}
	}
};

export default prepareConfigTitle;
