/* Redux Imports */

import { BHK, Area } from "../redux-magic/sub-interfaces/sub-interfaces";

const prepareConfigString = (
	transaction_type: string,
	property_type: Array<string>,
	bhk: BHK,
	no_of_seats: BHK,
	area: Area,
) => {
	if (property_type !== undefined) {
		if (property_type.includes("Studio Apartment")) {
			/* Studio Apartment */

			return "Studio";
		} else if (property_type.includes("Co-living/PG")) {
			/* Co-living */

			if (transaction_type === "rent") {
				return "Shared";
			} else {
				return bhk.max + " Bedrooms";
			}
		} else if (
			property_type.includes("Land (Residential)") ||
			property_type.includes("Land (Commercial)") ||
			property_type.includes("Land (Agricultural)") ||
			property_type.includes("Land (Industrial)") ||
			property_type.includes("Mining")
		) {
			/* Land types & Mining */

			return area.land_area.max.acre + " acre";
		} else if (
			property_type.includes("Plot") ||
			property_type.includes("Farm Plot") ||
			property_type.includes("Plot (Commercial)") ||
			property_type.includes("Building") ||
			property_type.includes("Office Space") ||
			property_type.includes("Shop") ||
			property_type.includes("Factory") ||
			property_type.includes("Warehouse")
		) {
			/* Plots, Building, Shop, Office space, Factory, Mining, Warehouse */

			return area.super_built_up_area.max.ft === area.super_built_up_area.min.ft
				? area.super_built_up_area.max.ft + " sqft"
				: area.super_built_up_area.min.ft + " - " + area.super_built_up_area.max.ft + " sqft";
		} else if (property_type.includes("Co-working")) {
			/* Co-working */

			if (transaction_type === "rent") {
				return no_of_seats.max + " seats";
			} else {
				return no_of_seats.max + " seats";
			}
		} else if (
			property_type.includes("Apartment") ||
			property_type.includes("Independent House") ||
			property_type.includes("Villa") ||
			property_type.includes("Row House") ||
			property_type.includes("Builder Floor Apartment") ||
			property_type.includes("Penthouse") ||
			property_type.includes("Farm House")
		) {
			/* Apartments, Independent House, Villa, Row House, Builder Floor Apartment, Penthouse, Farm House */

			return bhk.min === bhk.max ? bhk.max + " BHK" : bhk.min + " - " + bhk.max + " BHK";
		}
	}
};

export default prepareConfigString;
