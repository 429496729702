/* Common Imports */

import React from "react";

/* Component Imports */

import StateInterface from "../../../redux-magic/state-interface";
import ServiceLoadingCardMiniTwo from "../skeletonLoadingCard/ServiceLoadingCardMiniTwo";
import ServiceCard from "./ServiceCardGridMiniTwo/ServiceCardGridMiniTwo";

/* Styled Components */

const ServiceCardGridView = ({
	ServiceId,
	dispatch,
	session,
	profile_context,
	isEditor,
	source,
	id,
	service_carousel_width_height,
}: {
	ServiceId: any;
	dispatch: any;
	session: StateInterface["redux_session"];
	profile_context: any;
	isEditor?: boolean | undefined;
	source?: string;
	id?: string;
	service_carousel_width_height?: string;
}) => {
	const [serviceData, setServiceData] = React.useState<any>({});
	const [loading, setLoading] = React.useState(true);
	const [saved_by_current_user, setSavedByCurrentUser] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (ServiceId) {
			fetch(
				process.env.PRODUCTION_API_URL +
					`services/cards/${ServiceId}
				${service_carousel_width_height ? `?resize=${service_carousel_width_height?.toString()}` : ""}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "text/plain",
					},
				},
			)
				.then((res) => res.json())
				.then((data) => {
					setServiceData(data.service_data);
					setLoading(false);
				});
			if (session) {
				fetch(`${process.env.PRODUCTION_API_URL}saved-by-current-user`, {
					method: "POST",
					headers: {
						"Content-Type": "text/plain",
					},
					body: JSON.stringify({
						property_id: ServiceId,
						profile_context: profile_context,
						entity_type: "service",
					}),
				})
					.then((res) => res.json())
					.then((data) => {
						setSavedByCurrentUser(data.payload.saved_by_current_user ? data.payload.saved_by_current_user : false);
					});
			}
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [ServiceId, profile_context]);

	if (loading) {
		return <ServiceLoadingCardMiniTwo source={source} />;
	}
	return (
		<React.Fragment>
			<ServiceCard
				id={id}
				profile_context={profile_context}
				session={session ? session : null}
				dispatch={dispatch}
				loading={loading}
				_id={serviceData._id}
				slug={serviceData.slug}
				transaction_type={serviceData.transaction_type}
				services_type={serviceData ? serviceData.service_subtype : []}
				title={serviceData.title}
				images={serviceData.images}
				location={serviceData.location ? serviceData.location.title : ""}
				developer={serviceData.service_provider ? serviceData.service_provider.name : ""}
				price={serviceData.price}
				saved_content={saved_by_current_user}
				boosting={serviceData.boost_information}
				created_by={serviceData.created_by}
				show_contact_details={serviceData.show_contact_details}
				source={source}
				published_status={serviceData.status}
				isEditor={isEditor}
			/>
		</React.Fragment>
	);
};

export default ServiceCardGridView;
